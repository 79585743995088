/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { Suspense } from 'react'
import withPreview, { Loading } from 'services/withPreview'

// Theme
import Theme from 'styles/Theme'

// CSS
import 'styles/all.scss'

const PreviewTemplate = ({ postPreview, postType }) => {
  let PreviewTemplate = null
  
  /*
    Make sure the custom post types name is equal to the template name.
  */
  if(postPreview && postPreview.template === 'index.php') {
    PreviewTemplate = React.lazy(() => import(`src/pages/index`))
  } else if(postPreview && postPreview.template) {
    PreviewTemplate = React.lazy(() => import(`templates/${postPreview.template.replace('.php', '')}`))
  } else if(postType) {
    PreviewTemplate = React.lazy(() => import(`templates/${postType}`))
  }

  if(!PreviewTemplate) {
    return <span>Geen voorbeeld sjabloon gevonden</span>
  } 

  const data = {
    page: {
      path: postPreview.slug,
      content: postPreview.content.rendered,
      title: postPreview.title.rendered,
      categories: postPreview.categories,
      yoastMeta: null,
      acf: postPreview.acf
    }
  }

  return (
    <Theme>
      <Suspense fallback={<Loading description="Voorbeeld aan het laden" />}>
        <PreviewTemplate postPreview={postPreview} data={data} />
      </Suspense>
    </Theme>
  )
}

export default withPreview(PreviewTemplate)